import ButtonMedium4 from "components/elements/ButtonMedium4"
import CaptionText from "components/elements/CaptionText"
import ContentLarge from "components/elements/ContentLarge"
import ContentNormal from "components/elements/ContentNormal"
import Headline1 from "components/elements/Headline1"
import Headline3 from "components/elements/Headline3"
import ArrowRight from "components/icons/ArrowRight"
import ArrowRightAlt from "components/icons/ArrowRightAlt"
import Close from "components/icons/Close"
import Like from 'components/icons/Like'
import Dislike from 'components/icons/Dislike'
import { AppContext } from "contexts/AppContext"
import React, { useContext, useEffect, useRef, useState } from "react"
import { callAPI } from "utils/API"
import "./Help.css"
import { newLineToBreakTag } from "utils/general";
import Headline5 from "components/elements/Headline5"
import ContentSmall from "components/elements/ContentSmall"
import Headline6 from "components/elements/Headline6"
import { scrollToDiv } from "../../utils/general";
import { useLocation } from "react-router-dom";

const Help = () => {
    const contact = useRef(null)
    const location = useLocation();
    const FAQ = () => {
        const { accessibleBlogs, styleGuide,brandData} = useContext(AppContext);
        const [faqPosts, setfaqPosts] = useState([]);
        const [selectedFaq, setselectedFaq] = useState(0);
        const [showModal, setshowModal] = useState(false)
        
        const [category, setCategory] = useState(false);
        const [likeUnlike, setLikeUnlike] = useState('');
        const fetchFaqPosts = async () => {
            if (!accessibleBlogs.length) return []
            const BlogIDs = accessibleBlogs
                .filter(item => item.blogType === 'FAQ')
                .map((item) => item.BlogID);
            const sortBy = "poolOrder";
            const sortOrder = "ASC";
            const faqItems = await callAPI(
                "/post/getPostsOfMultipleBlogs",
                "GET",
                { query: { BlogIDs, sortBy, sortOrder } }
            ) || [];
            if (faqItems && faqItems.length > 0) {
                setfaqPosts(faqItems);
                const faqIndex = location.state.PostID ? faqItems.findIndex((item) => item.PostID === location.state.PostID) || 0 : 0
                setselectedFaq(faqIndex)
                if(!location?.state?.scrollToContact) return
                contact?.current?.scrollIntoView({
                    behavior: "smooth",
                    alignToTop: true,
                  });
            }
        }

        const updateSelectedFaq = (idx) => {
            setselectedFaq(idx)
            setshowModal(true)
        }

        const parseFAQsContent = (idx) => {
            if(!faqPosts?.length || !faqPosts[idx]?.elementContent)
                return null

            const post = faqPosts[idx]
            post.elementContent = newLineToBreakTag(post.elementContent)
            if(!post?.attachments?.length)
                return post.elementContent
            
            const links = post.attachments.filter(i => !!i.LinkID)
            if(!links?.length) 
                return post.elementContent
            
            post.elementContent.forEach((line, index) => {
                if(typeof line !== 'string') return line
                links.forEach((link) => {
                    if(typeof line === 'string') {
                        const texts = line.split(link.linkRef)
                        const linkElement = React.createElement('a', {href: link.linkRef, target: "_blank"}, link.linkRef || "")
                        line = texts.length > 1 ? texts.flatMap(t => [t, linkElement]).slice(0, -1) : line
                    }else {
                        line.forEach((l, ix) => {
                            if(typeof l !== 'string') return l
                            const texts = l.split(link.linkRef)
                            const linkElement = React.createElement('a', {href: link.linkRef, target: "_blank"}, link.linkRef || "")
                            l = texts.flatMap(t => [t, linkElement]).slice(0, -1) 
                            line[ix] = l
                        })
                    }
                    
                })
                post.elementContent[index] = line
            })
            return post.elementContent
        }

        const getFaqTitle = (idx) => !!faqPosts?.length ? faqPosts[idx].elementTitle : null

        const getFaq = async(idx) => {
            const getReaction = await callAPI(
            "/post/getFaqReaction",
                "GET");
            let filteredfaq = getReaction?.length && getReaction.filter((e) => {
                if (faqPosts?.length && e.ReactionTargetID === faqPosts[idx].PostID) {
                    return e;
                }
            })
            setLikeUnlike(filteredfaq[0]?.reactionType)
        }
        
        const postFaq = async(Description,ReactionType,ReactionID) => {
                const postReaction = await callAPI(
                "/post/postFaqReaction",
                "POST", {
                    body:
                    {
                        reactionData: JSON.stringify({ Description:Description,ReactionType:ReactionType,ReactionTargetID:ReactionID}),...ReactionID && { ReactionID }
                    }
            });
            getFaq(selectedFaq);
        }

        const likeButtonFn = () => {
            let temp ;
            if (likeUnlike === 'like') {
                temp=''
            }
            else { 
                temp='like'
            }
            const ReactionID = faqPosts && faqPosts.length ? faqPosts[selectedFaq].PostID : null;
            const FaqDescription = faqPosts && faqPosts.length ? faqPosts[selectedFaq].elementContent : null;
            postFaq(FaqDescription,temp,ReactionID)
        
        }

        const dislikeButtonFn = (idx) => {
            let temp ;
            if (likeUnlike ==='unlike') {
                temp=''
            }
            else {
                temp='unlike'
            }
            const ReactionID = faqPosts && faqPosts.length ? faqPosts[selectedFaq].PostID : null;
            const FaqDescription = faqPosts && faqPosts.length ? faqPosts[selectedFaq].elementContent : null;
            postFaq(FaqDescription,temp,ReactionID)
        }

        useEffect(async () => {
            fetchFaqPosts()
        }, [accessibleBlogs]);

        useEffect(() => {
            if (location?.state?.scrollToFaq === true) {
                scrollToDiv("faq")
            }    
        },[category])

        useEffect(() => {
            getFaq(0)
        }, [faqPosts])

        
        return <>
            <div className="faq-section" id="faq" >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 15}}>
                    <Headline1 style={{ lineHeight: '45px', marginLeft: -2 }}>FAQ</Headline1>
                    <ContentLarge translationKey="_FAQ_CAPTION_TEXT_">
                        Her kan du finde svar på de fleste spørgsmål
                    </ContentLarge>
                </div>
                <ButtonMedium4 style={{ maxWidth: 116 }} showIcon={false}
                    onClick={() => contact?.current?.scrollIntoView({
                        behavior: "smooth",
                        alignToTop: true,
                      })}
                    translationKey="_CONTACT_US_BUTTON_TEXT_">Kontakt os</ButtonMedium4>
            </div>
            {
                !!faqPosts && !!faqPosts.length &&
                <div className="faq-details-section"  style={{marginBottom:"120px" }}>
                    <div className="faq-list">
                        {
                            faqPosts.map((item, idx) => <div
                                    onClick={() => {
                                        setCategory(!category)
                                        updateSelectedFaq(idx)
                                        getFaq(idx)
                                    }}
                                    key={idx}
                                    className="faq-title-item"
                                    style={{ color: styleGuide.color16, borderBottom: `1px solid ${styleGuide.color4}` }}>
                                    <ContentNormal style={{ color: idx === selectedFaq ? styleGuide.color7 : styleGuide.color16 }}>
                                        {item.elementTitle || ''}
                                    </ContentNormal>
                                    <ArrowRightAlt fill={idx === selectedFaq ? styleGuide.color7 : styleGuide.color16} />
                                </div>
                            )
                        }
                    </div>
                    <div className={`faq-content ${!showModal ? 'faq-modal-hide' : ''}`}>
                        <Close onClick={() => setshowModal(false)} className="faq-close-icon" />
                        <Headline3>
                            {getFaqTitle(selectedFaq)}
                            </Headline3>
                        <div className='faq-content-div'>
                            <ContentNormal>
                                {
                                    parseFAQsContent(selectedFaq)
                                }
                            </ContentNormal>
                            {
                                brandData?.features?.enableLikeUnlikeButtons && <div className='like-dislike-div'>
                                    <button onClick={() => likeButtonFn()} className='like-button'><Like fill={likeUnlike === 'like' ? '#008ecc' : 'black'} /></button>
                                    <button onClick={() => dislikeButtonFn()} className='like-button'><Dislike fill={likeUnlike === 'unlike' ? 'red' : 'black'} /></button>
                                </div>
                            }
                        </div>
                        
                    </div>
                </div>
            }
        </>
    }


    const Contact = () => {
        const { styleGuide, brandData} = useContext(AppContext);
        const brandSlug = brandData && brandData.brandSlug;
        const contactEmail = brandData && brandData.contactEmail;
        const contactPhone = brandData && brandData.contactPhone;
        const textColor = brandData.features.personalShopperTextColorBlack ? styleGuide.color18 : styleGuide.color1;
        const openingHoursMonThurs = brandData.features && brandData.features.openingHoursMonThurs || null
        const openingHoursFri = brandData.features && brandData.features.openingHoursFri || null
        useEffect(() => {
            if (location?.state?.scrollToContact === true) {
                const contactSection = document.querySelector("#contact-section")
                if(!contactSection) return
                contactSection.scrollIntoView({alignToTop: true, behavior: "smooth"})
            }
        }, [])
        return <>
            <div id="contact-section" ref={contact} style={{ display: "flex", flexDirection: "column", gap: "1rem"}} >

                <Headline1 translationKey="_CONTACT_US_" className="faq-contact-us-title">Kontakt os</Headline1>

                <ContentLarge translationKey="_FOR_QUESTIONS_SEND_MAIL_" className="faq-contact-content">Har du spørgsmål til din ordre eller produkter, også dem du ikke lige finder i shoppen, så send en mail til os.</ContentLarge>

                <div id="faq-contact-flex-box">
                    <div className="faq-Contact-box" style={{ background: styleGuide.color2 }}>
                        <Headline5 translationKey="_CONTACT_INFORMATION_">Kontaktinformation</Headline5>
                        <div style={{ display: "flex", flexDirection: "column", wordWrap: "anywhere" }}>
                            <ContentSmall>Mail</ContentSmall>

                            <Headline6>{contactEmail ? contactEmail : brandSlug ? `support+${brandSlug}@upgrader.club` : `support@upgrader.club`}</Headline6>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <ContentSmall translationKey="_TELEPHONE_">Telefon</ContentSmall>

                            <Headline6>{contactPhone ? contactPhone : '+45 31 15 84 11'}</Headline6>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <ContentSmall translationKey="_OPENING_HOURS_CAPTION_">Åbningstider</ContentSmall>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 8 }}>
                                <ContentSmall translationKey="_OPENING_HOURS_LABEL_MONDAY_TO_THURSDAY_" style={{marginRight: 8}}>Mandag til torsdag:</ContentSmall>
                                <Headline6 style={{textAlign: 'end'}}>{openingHoursMonThurs ? openingHoursMonThurs : '8.30 - 16.00'}</Headline6>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 8, alignSelf: 'end' }}>
                                <ContentSmall translationKey="_OPENING_HOURS_LABEL_FRIDAY_" style={{marginRight: 8}}>Fredag:</ContentSmall>
                                <Headline6>{openingHoursFri ? openingHoursFri : '8.30 - 14.30'}</Headline6>
                            </div>
                        </div>
                    </div>
                    {brandData && brandData.features && brandData.features.personalshopper && <div className="faq-Personal-shopper-box" style={{ background: styleGuide.color14 }}>
                        <Headline5 translationKey="_FOR_PERSONAL_SHOPPER_SERVICE_" style={{ color: textColor }}>Hvis du ønsker at benytte dig af vores personal shopper service, skal du oplyse følgende:</Headline5>
                        <ul className="faq-contact-list" style={{ color: textColor }}>
                            <li translationKey="_INSERT_A_LINK_" >Indsæt link til den ønskede vare</li>
                            <li translationKey="_SPECIFY_VARIANT_SIZE_COLOR_" >Angiv variant, størrelse, farve mm.</li>
                        </ul>
                        <ContentNormal translationKey="_INVESTIGATE_ITEM_DELIVERY_" style={{ color: textColor }}>Så vil vi undersøge, om vi kan levere den ønskede vare, samt hvad den koster i point.</ContentNormal>
                    </div>}
                </div>
            </div>

        </>
    }

    return <>
        <div style={{display: 'flex', gap: '3rem', flexDirection: 'column'}}>
            <FAQ />
            <Contact />
        </div>

    </>

}
export default Help