import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Modal,Button } from "react-bootstrap";
import Translate, { translate } from "utils/Translate";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import { textStyles } from "styles/styles";
import { sessionGrant, sessionAccess } from "utils/API";
import { AppContext } from "contexts/AppContext";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import CookiePopUp from "components/blocks/cookiePopUp/CookiePopUp";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import BrandConsentPopup from "components/blocks/consent/BrandConsentPopup";
import GdprPopUp from "components/blocks/consent/Gdpr";
import { callAPI } from "utils/API";
import { isNumber, isPhoneOrEmail } from "utils/general";
import Headline2 from "components/elements/Headline2";
import ContentNormal from "components/elements/ContentNormal";
import CustomizedDialogs from 'components/elements/CustomizedDialogs';
import { browserName, browserVersion } from 'react-device-detect';
import UnderMaintenance from './underMaintenance'
import Activity from "utils/Activity";
import { Link } from "react-router-dom";
import ContentLarge from "components/elements/ContentLarge";
import LanguageSelector from "components/blocks/LanguageSelector/LanguageSelector";
import TagManager from "react-gtm-module";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const fromPage =
    location.state &&
    location.state.fromPage &&
    location.state.fromPage.pathname
      ? location.state.fromPage.pathname
      : "/";

  const cookieName = "cookieConsent";
  const [isCookieAccepted, setCookieAccepted] = useState(
    document.cookie && document.cookie.includes(cookieName)
  );
  const ctxtval = useContext(AppContext);
  const { userData, setUserData, setTranslations, brandData, styleGuide,langCode } = useContext(AppContext);
  const [userIdentifier, setUserIdentifier] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [grant, setGrant] = useState(false);
  const [loginProblemModal, setLoginProblemModal] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const [accessCode, setAccessCode] = useState("");
  const [showGDPRConsent, setShowGDPRConsent] = useState(false);
  const [showBrandConsent, setShowBrandConsent] = useState(false);
  const accessCodeField = useRef();
  const [brandTermsUrl, setBrandTermsUrl] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [identifierInvalid, setidentifierInvalid] = useState(false);
  const [browserNotSupport, setBrowserNotSupport] = useState(false);
  const brandSlug = brandData && brandData.brandSlug;
  const contactEmail = brandData && brandData.contactEmail;
  const contactPhone = brandData && brandData.contactPhone;
  const isDownForMaintenance = brandData?.features?.isDownForMaintenance;
  const shutDownAllBrands = brandData?.features?.shutDownAllBrands;
  let { loginPageTitle, loginPageDescription } = brandData && brandData.features
  
  const title = loginPageTitle && typeof loginPageTitle === 'object' ?
    loginPageTitle.hasOwnProperty(langCode) ? loginPageTitle[langCode] : 'Overskrift for velkomst'
    : loginPageTitle ? loginPageTitle : 'Overskrift for velkomst'
  const description = loginPageDescription && typeof loginPageDescription === 'object' ?
    loginPageDescription.hasOwnProperty(langCode) ? loginPageDescription[langCode] : ''
    : loginPageDescription ? loginPageDescription : ''


  const loginPageBrandLogoHeight =
    brandData &&
    brandData.features &&
    brandData.features.loginPageBrandLogoHeight
      ? `${brandData.features.loginPageBrandLogoHeight}`
      : null;
  const loginPageBrandLogoWidth =
    brandData &&
    brandData.features &&
    brandData.features.loginPageBrandLogoWidth
      ? `${brandData.features.loginPageBrandLogoWidth}`
      : null;
  const loginPageTextColor = brandData.features.loginPageTextColorBlack ? styleGuide.color18 : styleGuide.color1;
  const showCustomConsentGDPRAndNews = brandData && brandData.features && brandData.features.customUserConsentGDPRAndNews || false

  const closeGdpr = () => {
    setShowGDPRConsent(false);
    setAccessCode("");
    setUserIdentifier("");
    setGrant(false);
    localStorage.clear();
  };

  const acceptGDPR = async () => {
    const gdprUpdate = await callAPI(
      "session/updateConsentGDPR",
      "POST",
      {},
      accessToken
    );
    // Activity({activityType: 'click',activityName: 'consent',activityArgument: 'GDPR',activityArgumentID: ''})
    if (gdprUpdate && gdprUpdate.status) {
      setShowGDPRConsent(false);
      submitCode(null, true);
    }
  };

  const closeBrandConsent = () => {
    setShowBrandConsent(false);
    setAccessCode("");
    setUserIdentifier("");
    setGrant(false);
    localStorage.clear();
  };

  const acceptBrandConsent = async () => {
    const brandConsent = await callAPI(
      "session/updateTerms",
      "POST",
      {},
      accessToken
    );
    // Activity({activityType: 'click',activityName: 'consent',activityArgument: 'Brand',activityArgumentID: ''})
    if (brandConsent && brandConsent.status) {
      setShowBrandConsent(false);
      submitCode(null, true);
    }
  };

  const requestCode = async (e, manual = false) => {
    if (e) e.preventDefault();
    setidentifierInvalid(false);
    const sessionGrantCallResponse = await sessionGrant(userIdentifier);
    if (sessionGrantCallResponse) {
      if (sessionGrantCallResponse.error) {
        setError(sessionGrantCallResponse.error);
        setSuccess(false);
        setGrant(false);
        setidentifierInvalid(manual ? true : false);
      } else {
        setError("");
        setGrant(true);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false)
        }, 5000)
        accessCodeField.current.focus();
      }
    }
  };

  const submitCode = async (e, trackFirstLogin = false) => {
    if (e) e.preventDefault();
    const sessionAccessCallResponse = accessToken
      ? await callAPI("session/getSession", "GET", {}, accessToken)
      : await sessionAccess(accessCode, userIdentifier);
    if (!accessToken && sessionAccessCallResponse) {
      setAccessToken(sessionAccessCallResponse.accessToken);
    }
    if (sessionAccessCallResponse) {
      if (sessionAccessCallResponse.error) {
        if (sessionAccessCallResponse.error.status) {
          document.getElementById("invalidPassword").style.borderBottomColor = "#e51500";
          setErrorCode(sessionAccessCallResponse.error.status)
        }
        setError(sessionAccessCallResponse.error);
        setSuccess(false);
      } else {
        setError("");
        setSuccess(true);
        if (
          sessionAccessCallResponse.user &&
          sessionAccessCallResponse.user.UserID
        ) {
          if (!sessionAccessCallResponse.user.consentGDPR || (showCustomConsentGDPRAndNews && !sessionAccessCallResponse.user.consentNews)) {
            setShowGDPRConsent(true);
          } else if (
            sessionAccessCallResponse.user.brandAccessTermsURL &&
            !sessionAccessCallResponse.user.userAccessTerms
          ) {
            setBrandTermsUrl(
              sessionAccessCallResponse.user.brandAccessTermsURL
            );
            setShowBrandConsent(true);
          } else {
            if(brandData && brandData.features && brandData.features.LaunchCPRPopupAfterLogin){
              let getCPR = await callAPI("cpr/existsCPR", "GET", {}, sessionAccessCallResponse.accessToken)
              localStorage.setItem('cprExists', !!getCPR ? 1 : 0)
            }
            localStorage.setItem(
              "accessToken",
              sessionAccessCallResponse.accessToken || ""
            );
            localStorage.setItem("langCode", langCode || "");
            sessionAccessCallResponse.user.walletAmountPoints =
              isNaN(sessionAccessCallResponse.user.walletAmountPoints) ||
              !sessionAccessCallResponse.user.walletAmountPoints
                ? 0
                : sessionAccessCallResponse.user.walletAmountPoints;
            setUserData(sessionAccessCallResponse.user);
            if(!!trackFirstLogin && sessionAccessCallResponse.user.userEmail){
              TagManager.dataLayer({
                dataLayer: {
                    event: 'consent_gdpr',
                    user_mail: sessionAccessCallResponse.user.userEmail
                },
              })
            }else if(sessionAccessCallResponse.user.userEmail){
              TagManager.dataLayer({
                dataLayer: {
                    event: 'user_login',
                    user_mail: sessionAccessCallResponse.user.userEmail
                },
              })
            }
            Activity({activityType: 'access',activityName: 'login',activityArgument: '',activityArgumentID: ''})
          }
        }
        // if (
        //   sessionAccessCallResponse.translations &&
        //   sessionAccessCallResponse.translations.length
        // ) {
        //   const object = sessionAccessCallResponse.translations.reduce(
        //     (obj, item) => (
        //       (obj[item.translationKey] = item.translationValue), obj
        //     ),
        //     {}
        //   );
        //   const staticTranlsations = Translations[ctxtval.langcode];
        //   const tranlations = { ...object, ...staticTranlsations };
        //   setTranslations(tranlations);
        // }
      }
    }
  };

  const handleIdentifierKeyPress = (target) => {
    if (target.charCode === 13) {
      requestCode(target, true);
    }
  };
  const handleAccessCodeKeyPress = (target) => {
    if (target.charCode === 13) {
      submitCode(target);
    }
  };
  const handleRequestCodeKeyPress = (target) => {
    if (target.charCode === 13) {
      setSuccess(false)
      setTimeout(() => {
        accessCodeField.current.focus();
      }, 1000)
    }
  };

  useEffect(() => {
    !!accessCode && accessCode.length >= 6 && submitCode();
  }, [accessCode]);

  // useEffect(() => {
  //   !!userIdentifier && isPhoneOrEmail(userIdentifier) && requestCode();
  // }, [userIdentifier]);

  useEffect(() => {
    switch (browserName) {
      case 'Firefox':
        if(browserVersion < 90)
        setBrowserNotSupport(true)
        break;
      case 'Chrome':
        if(browserVersion < 90)
        setBrowserNotSupport(true)
        break;
      case 'Safari':
        if(browserVersion < 14)
        setBrowserNotSupport(true)
        break;
      case 'Mobile Safari':
        if(browserVersion < 13)
        setBrowserNotSupport(true)
        break;
      case 'Edge':
        if(browserVersion < 90)
        setBrowserNotSupport(true)
        break;
      case 'Opera':
        if(browserVersion < 80)
        setBrowserNotSupport(true)
        break;
      case 'Samsung Internet':
        if(browserVersion < 14)
        setBrowserNotSupport(true)
        break;
      case 'IE':
      default:
        setBrowserNotSupport(true)
        break;
    }
  }, [])
  
  useEffect(() => {
    if (errorCode === 429) {
      setTimeout(() => {
      window.location.reload(true)
      }, 3000)
    }
  }, [errorCode])

  const IdentifierError = () => {
    const res = isNumber(userIdentifier.replace("+", "")) ? (
      <ValidationMessage
        heading={translate('_LOGIN_ERROR_MOBILE_', 'Det er ikke muligt at logge ind med det indtastede mobilnummer.')}
        content={translate('_LOGIN_ERROR_MOBILE_QUESTION_', 'Er det blevet tastet korrekt? Eller er du evt. blevet oprettet med et andet mobilnummer?')}
      />
    ) : (
      <ValidationMessage
      heading={translate('_LOGIN_ERROR_EMAIL_', 'Det er ikke muligt at logge ind med den indtastede email.')}
      content={translate('_LOGIN_ERROR_EMAIL_QUESTION_', 'Er den blevet tastet korrekt? Eller er du evt. blevet oprettet med en anden mail?')}
    />
    )
    return res;
  };

  const handleLoginInput = (e) => {
    if (errorCode) {
      document.getElementById("invalidPassword").style.borderBottomColor = '#ced4da'
      setErrorCode(null)
    }
    setidentifierInvalid(false);
    setUserIdentifier(e.target.value);
  }

  if (userData && userData.UserID) {
    return <Navigate to={fromPage} replace={true} />;
  }
  
  return (
    <>
      {(isDownForMaintenance || shutDownAllBrands) && <div style={{ width: '100%' }}>
        <UnderMaintenance />
      </div>}
      {(!isDownForMaintenance && !shutDownAllBrands) && <div>
        {browserNotSupport ? <CustomizedDialogs /> : null}
        <Container fluid className="p-0 d-block overflow-hidden">
          <Row>
            <Col lg={4} style={{ padding: "100px 80px 0px", position: 'relative' }}>
              <LanguageSelector />
              {brandData &&
                brandData.features &&
                brandData.features.brandLogoPosition &&
                brandData.features.brandLogoPosition.toLowerCase() === "left" && (
                  <img
                    width={
                      loginPageBrandLogoWidth ? loginPageBrandLogoWidth : "auto"
                    }
                    height={
                      loginPageBrandLogoHeight ? loginPageBrandLogoHeight : 60
                    }
                    style={{ marginBottom: "30px" }}
                    src={brandData.brandLogo}
                  />
                )}
              <Row>
                <Headline2 style={{ marginBottom: 20 }} translationKey="_LOGIN_PAGE_LOGIN_TEXT_" fallbackText="Log ind" />
              </Row>
              <Row style={{ marginBottom: "40px", position: "relative" }}>
                <p style={{ ...textStyles.contentNormal, fontFamily: styleGuide.secondaryFontFamily }}>
                  <Translate translationKey="_LOGIN_ENTER_CREDENTIAL_MESSAGE_">
                    Indtast dit mobilnummer eller din e-mail. Så sender vi en
                    engangskode, du kan logge ind med.
                  </Translate>
                </p>
                {!isCookieAccepted && (
                  <CookiePopUp callback={setCookieAccepted} />
                )}
              </Row>
              <Row>
                <Form
                  className="p-0"
                  autoComplete="off"
                  style={{ fontSize: "12px" }}
                  onSubmit={() => { }}
                >
                  <Form.Group as={Row} className="mb-3" controlId="email">
                    <Col sm={12}>
                      <Form.Control
                        value={userIdentifier}
                        style={{ ...FormInput, fontFamily: styleGuide.secondaryFontFamily }}
                        className="loginFormInput"
                        type="text"
                        placeholder={translate(
                          "_LOGIN_INPUT_EMAIL_PLACEHOLDER_",
                          "E-mail eller telefon nummer "
                        )}
                        onChange={(e) => handleLoginInput(e)}
                        disabled={!isCookieAccepted || accessCode || browserNotSupport}
                        onKeyPress={(e) => handleIdentifierKeyPress(e)}
                      />
                      {identifierInvalid && <IdentifierError />}
                    </Col>
                  </Form.Group>
                  <Row style={{ padding: "0px 0px 0px 15px" }}>
                    {grant ? (
                      <ButtonMedium4
                        showIcon={false}
                        style={{ flex: "0.625", justifyContent: "center", fontSize: 14, fontFamily: styleGuide.secondaryFontFamily }}
                        disabled={!isCookieAccepted}
                        fallbackText="Send kode igen"
                        translationKey="_LOGIN_BUTTON_SENDCODE_AGAIN_TEXT_"
                        onClick={(e) => requestCode(e, true)}
                      />
                    ) : (
                      <ButtonMedium1
                        showIcon={false}
                        style={{ flex: "0.422", justifyContent: "center", fontSize: 14, fontFamily: styleGuide.secondaryFontFamily }}
                        disabled={!isCookieAccepted}
                        fallbackText="Send kode"
                        translationKey="_LOGIN_BUTTON_SENDCODE_TEXT_"
                        onClick={(e) => requestCode(e, true)}
                      />
                    )}
                  </Row>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalEmail"
                    style={{ marginTop: "50px" }}
                  >
                    <Col sm={12}>
                      <Form.Control
                        id='invalidPassword'
                        style={{ ...FormInput, fontFamily: styleGuide.secondaryFontFamily }}
                        type="text"
                        value={accessCode}
                        placeholder={translate(
                          "_LOGIN_INPUT_CODE_PLACEHOLDER_",
                          "Engangskode"
                        )}
                        onChange={(e) => setAccessCode(e.target.value)}
                        onKeyPress={(e) => handleAccessCodeKeyPress(e)}
                        ref={accessCodeField}
                        disabled={!isCookieAccepted || !grant}
                      />
                    </Col>
                    {errorCode === 401 && <Row sm={12}>
                    <p
                      style={{
                        marginTop: 8,
                        marginLeft:15,
                        color:'#e51500',
                        fontSize: 12, fontFamily: styleGuide.secondaryFontFamily
                      }}
                    >
                      {translate(
                        "_WRONG_CODE_ENTERED_",
                        "Forkert kode indtastet - prøv igen"
                      )}
                    </p>
                    </Row>}
                    { errorCode === 429 && <Row sm={12}>
                    <p
                      style={{
                        marginTop: 8,
                        marginLeft:15,
                        color:'#e51500',
                        fontSize: 12,
                        fontFamily: styleGuide.secondaryFontFamily
                      }}
                    >
                      {translate(
                        "_MAXIMUM_ATTEMPT_REACHED_",
                        "Kode er indtastet forkert 3 gange. Du skal bede om ny kode ved at indtaste dine informationer igen"
                      )}
                    </p>
                    </Row>}
                  </Form.Group>
                  <Row style={{ padding: "0px 15px" }}>
                    <ButtonLarge1
                      style={{ fontSize: 14, fontFamily: styleGuide.secondaryFontFamily }}
                      onClick={(e) => submitCode(e)}
                      fallbackText="Log ind"
                      translationKey="_LOGIN_BUTTON_LOGIN_TEXT_"
                      disabled={!isCookieAccepted || !grant}
                    />
                  </Row>
                  <Row>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: 16,
                        cursor: "pointer",
                        fontSize: 14, fontFamily: styleGuide.secondaryFontFamily
                      }}
                      onClick={() => setLoginProblemModal(true)}
                    >
                      {translate(
                        "_LOGIN_PROBLEM_TEXT_",
                        "Problemer med at logge ind?"
                      )}
                    </p>
                  </Row>
                  {brandData && brandData.features.registerUser && <Row>
                    <Link to={'/register'}
                      style={{
                        textAlign: "center",
                        marginBottom: 5,
                        cursor: "pointer",
                        fontSize: 12, fontFamily: styleGuide.secondaryFontFamily
                      }}>
                      {translate('_CREATE_NEW_USER_ACCOUNT_', 'Opret ny bruger')}
                    </Link>
                  </Row>}
                </Form>
              </Row>
            </Col>
            <Col className="d-none d-sm-none d-md-none d-lg-block p-0" lg={8}>
              <div
                style={{
                  ...loginRight,
                  backgroundImage:
                    brandData &&
                      brandData.features &&
                      brandData.features.loginImageURL
                      ? `url(${brandData.features.loginImageURL})`
                      : `url('/login-banner.png')`,
                }}
              >
                <div style={loginRightContent}>
                  {(!brandData ||
                    !brandData.features ||
                    !brandData.features.brandLogoPosition ||
                    (brandData &&
                      brandData.features &&
                      brandData.features.brandLogoPosition &&
                      brandData.features.brandLogoPosition.toLowerCase() !==
                      "left")) && (
                      <img alt="Brand Logo"
                        width={
                          loginPageBrandLogoWidth ? loginPageBrandLogoWidth : "auto"
                        }
                        height={
                          loginPageBrandLogoHeight ? loginPageBrandLogoHeight : 60
                        }
                        src={brandData.brandLogo}
                      />
                    )}
                  <Headline2 style={{ color: loginPageTextColor, marginBottom: 20 }} fallbackText={title} />
                  {description ? (
                    <div>
                      <ContentNormal style={{ color: loginPageTextColor, whiteSpace: "pre-line" }} fallbackText={description} />
                    </div>
                  ) : (
                    <>
                      <p style={{ ...textStyles.contentNormal, color: loginPageTextColor }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua.
                      </p>
                      <p style={{ ...textStyles.contentNormal, color: loginPageTextColor }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          show={loginProblemModal}
          onHide={() => setLoginProblemModal(false)}
          centered
        >
          <Modal.Header
            className="pb-0 border-0"
            closeButton
            style={{ fontSize: 9 }}
          />
          <Modal.Body className="pt-0">
            <p style={textStyles.headline6}>
              {
                translate(
                  "_LOGIN_PROBLEM_TEXT_",
                  "Problemer med at logge ind?"
                )
              }</p>
            <p style={textStyles.contentSmall}>
              {
                translate(
                  "_LOGIN_PROBLEM_CHECK_SPAM_MESSAGE_",
                  `Kontrollér dit spamfilter, hvis du har bedt om koden på e-mail. Hvis du stadig ikke kan logge ind kan du kontakte support på: `
                )
              }
            </p>
            <p style={{ ...textStyles.contentSmall, marginBottom: 0 }}>
              Mail: {contactEmail ? contactEmail : brandSlug ? `support+${brandSlug}@upgrader.club` : `support@upgrader.club`}
              <br />
              {translate('_TELEPHONE_' ,'Telefon')}: {contactPhone ? contactPhone : '+45 31 15 84 11'}
            </p>
          </Modal.Body>
        </Modal>
        <Form onKeyPress={handleRequestCodeKeyPress}>
        <Modal
          show={success}
          onHide={() => setSuccess(false)}
          centered
        >
          <Modal.Header
            className="pb-0 border-0"
            closeButton
            style={{ fontSize: 9 }}
          />
          <Modal.Body className="pt-0">
            <ContentLarge fallbackText="Din kode er afsendt" translationKey="_SEND_CODE_SUCESS_TEXT_" />
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ ...ButtonStyles, fontFamily: styleGuide.secondaryFontFamily, backgroundColor: styleGuide.color16 }} onClick={() => setSuccess(false)}>
            {translate('_BUTTON_CAPTION_CLOSE_', 'Luk')}
            </Button>
          </Modal.Footer>
        </Modal>
        </Form>
        {showGDPRConsent && (
          <GdprPopUp accept={() => acceptGDPR()} close={() => closeGdpr()}/>
        )}
        {showBrandConsent && (
          <BrandConsentPopup
            termsUrl={brandTermsUrl}
            accept={() => acceptBrandConsent()}
            close={() => closeBrandConsent()}
          />
        )}
      </div>}
    </>
  );
};

const ValidationMessage = (props) => {
  const { styleGuide } = useContext(AppContext);
  const {heading,content} = props
  const [loginError,setLoginError] = useState(true)
  return (
    <Modal
    show={loginError}
    onHide={() => setLoginError(false)}
    centered
  >
      <Modal.Body className="pt-0">
          <p style={{
            ...textStyles.headline6,
            ...ValidationStyles,
            fontFamily: styleGuide.secondaryFontFamily
            }}>
            {translate('_VALIDATION_ERROR_', 'Valideringsfejl')}
        </p>
        <Translate translationKey="_LOGIN_INVALID_TEXT_LINE_1_">
        <p style={{...textStyles.contentSmall,marginBottom:0, fontSize: 14, fontFamily: styleGuide.secondaryFontFamily }}>
          {heading}
      </p> 
        </Translate>
        <Translate translationKey="_LOGIN_INVALID_TEXT_LINE_2_">
        <p style={{ ...textStyles.contentSmall, marginBottom: 0, fontSize: 14, fontFamily: styleGuide.secondaryFontFamily  }}>
          {content}
          </p>
        </Translate>
        </Modal.Body>
        <Modal.Footer>
        <Button style={{ ...ButtonStyles, fontFamily: styleGuide.secondaryFontFamily }} onClick={() => setLoginError(false)}>
        {translate('_BUTTON_CAPTION_CLOSE_', 'Luk')}
        </Button>
      </Modal.Footer>
  </Modal>
  )
}

const loginRight = {
  minHeight: "100vh",
  backgroundRepeat: `no-repeat`,
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
};
const loginRightContent = {
  marginLeft: "104px",
  marginBottom: 100,
  position: "absolute",
  width: "350px",
  top: 270,
};
const FormInput = {
  borderRight: "none",
  borderLeft: "none",
  borderTop: "none",
  borderBottomWidth: "2px",
  borderRadius: 0,
  paddingLeft: "15px",
  paddingRight: "15px",
  fontSize: 14
};
const ValidationStyles = {
  width: '100%',
  height:'30px',
  backgroundColor: '#cc3333',
  padding: '14px 16px',
  fontWeight: 600,
  fontSize:'16px',
  color: '#ffffff',
  display:'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  marginTop:15
}
const ButtonStyles = {
  backgroundColor: '#cc3333',
  border: 'none',
  color: '#ffffff',
  fontWeight: 600,
  fontSize: '14px'
}

export default Login;
