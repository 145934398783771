import { callAPI } from "utils/API";
import { AppContext } from "contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Video, { getVideoType } from "components/elements/Video";
import Image from "components/elements/Image";
import Headline3 from "components/elements/Headline3";
import ContentNormal from "components/elements/ContentNormal";
import "./PostDetails.css"
import ProductNormal from "components/elements/ProductNormal";
import Headline4 from "components/elements/Headline4";
import _ from 'lodash'
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import { translate } from "utils/Translate";
import { RawHTML, pointsConvertor } from "utils/general";
import CustomCarousel from "components/blocks/customCarousel/CustomCarousel";
import { Input } from "@mui/material";
import ContentLarge from "components/elements/ContentLarge";
import Headline2 from "components/elements/Headline2";
import IconButtonMedium from "components/elements/IconButtonMedium";
import { Save } from "@material-ui/icons";
import ArrowRight from "components/icons/ArrowRight";
import ExplainerText from "components/elements/ExplainerText";
import ExplainerTextTiny from "components/elements/ExplainerTextTiny";

const PostDetails = (props) => {
  const {postType=""} = props 
  const { styleGuide, defaultTheme, themeConfig, brandData} = useContext(AppContext);
  const { PostID } = useParams()
  const [postDetails, setPostDetails] = useState(null);
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [postContent, setPostContent] = useState(null);
  const [postTitle, setPostTitle] = useState(null);
  const [products, setProducts] = useState(null);
  const [imgHeight,setImgHeight]=useState()
  const [productSlice,setProductSlice] = useState(2);
  const navigate = useNavigate();

  const fetchPostDetails = async (id) => {
    if(isNaN(id)) return 
    let postDetails = await callAPI(`/posts/${id}`, "GET");
    if (postDetails) {
      if(postDetails.blogType === "FAQ") {
        navigate("/help", {state: {PostID: postDetails.PostID}})
      }
      if (postDetails.attachments && Array.isArray(postDetails.attachments)) {
        postDetails.imageURL = !!postDetails.attachments.length ? postDetails.attachments[0].imageOriginSource : 'https://i.imgur.com/qtmXhnZ.jpg';
      }
      setPostDetails(postDetails);
      return
    }
    setPostDetails({})
    return
  }

  const fetchProducts = async () => {
    let products = await callAPI("/product/popularProducts", "GET");
    if(products && !!products.length){
      products = products.map( product => {
        if(product.images && product.images.length){
          product.images.sort((a,b) => a.poolOrder - b.poolOrder);
          product.imageSrc = product.images[0].imageOriginSource;
        }
        return product
      });
      setProducts(products)
      return
    }
    setProducts(null)
  }

  const getPostDetailDivHeight = (element) => {
    if (element && element.clientHeight > 900) {
      const NoOfProducts = Number(Math.round(element.clientHeight/400))
      setProductSlice(NoOfProducts> 4 ? 4 : NoOfProducts);
    }
  };

  const isVideo = (attachments) => {
    const videoAttachment = attachments.filter(item => item.LinkID && item.linkRef);
    return videoAttachment.length ? true : false;
  }

  const getVideoLink = (attachments) => {
    const videoAttachment = attachments.filter(item => item.LinkID && item.linkRef);
    const link = videoAttachment.length ? videoAttachment[0].linkRef : null
    const videoInfo = getVideoType(link)
    return videoInfo ? videoInfo.url : null
  }

  useEffect(() => {
    fetchPostDetails(PostID)
    if(postType === "taxCampaign") return
    !themeConfig.hideHaveYouSeenBlock && fetchProducts()
  }, [PostID]);

  useEffect(() => {
    if(!postDetails) return null
    if(postDetails.attachments && Array.isArray(postDetails.attachments)) {
      setImageUrl(postDetails.bannerImage || (!!postDetails.attachments.length && postDetails.attachments[0].imageOriginSource ? postDetails.attachments[0].imageOriginSource : 'https://i.imgur.com/qtmXhnZ.jpg'))
      setVideoUrl(!!postDetails.videoSrc ? postDetails.videoSrc : isVideo(postDetails.attachments) ? getVideoLink(postDetails.attachments) : null)  
    }
    setPostContent(postDetails.elementContent)
    setPostTitle(postDetails.elementTitle)
  }, [postDetails]);

  useEffect(() => {
    let Width = window.screen.width
        setImgHeight(Width / 2)
    }, []);

  useEffect(() => {
    window.addEventListener('resize', _.debounce(() => {
        let imgWidth = window.screen.width
        setImgHeight(imgWidth / 2)
    }, 200));
    return () => {
    window.removeEventListener('resize', _.debounce(() => {
        let imgWidth = window.screen.width
        setImgHeight(imgWidth / 2)
    }, 200));
    }
},[imageUrl])


  if (!postDetails) return <div />
  const bannerStyle1 = { display: 'flex', width: '100%', height: !!imageUrl? imgHeight : null,flexDirection: 'column', maxHeight: styleGuide.bannerMaxHeight }
  const bannerStyle = defaultTheme == 'ThemeONE' ? { ...bannerStyle1 } : defaultTheme == 'ThemeTWO' ? { ...bannerStyle1, marginTop: '-180px' } : { ...bannerStyle1,marginTop: '-20px' };

  return (
    <>
      {/* {postDetails && JSON.stringify(postDetails)} */}
      <div style={bannerStyle}>
        <div>
          {
            !!imageUrl
              ? <div style={{height:imgHeight, width:'100%', overflow: 'hidden', position:'absolute', left:0, right:0, maxHeight: styleGuide.bannerMaxHeight}}>
                <Image src={`url(${imageUrl})`}
                  style={{
                    width: '100%',
                    height:'100%',
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }}
                />
                </div>
              : null
          }
        </div>
        </div>
        <div style={{ marginTop: '50px'}} >
        <Headline3 style={{ fontSize: 40, lineHeight: '60px',wordBreak:'break-word'}} >{postTitle}</Headline3>
        <div style={{display:'flex', flexDirection: 'row', marginTop: 40, gap: "1.5rem"}}>

          <div className={postType !== "taxCampaign" ? "post-content" : "post-campaign-content"} style={{display:'flex'}}>
            <ContentNormal>
              <div style={{wordBreak:'break-word'}}ref={getPostDetailDivHeight}>
                {/* <span>{newLineToBreakTag(parseLinks(postContent))}</span> */}
                <RawHTML>
                  {postContent}
                </RawHTML>                
                {
                  !!videoUrl 
                    ? <Video url={videoUrl} style={{width: "100%", marginTop: "2rem" }} />
                    : null
                }
              </div>
            </ContentNormal> 
          </div>
          {
            !!postDetails?.attachments?.length && <div style={{width:"100%", flex:0.4}}>
            <CustomCarousel>
              {
                !!(postType === "taxCampaign") && postDetails?.attachments?.map((item, index) => 
                <Image 
                  key={index} 
                  src={`url(${item.imageOriginSource})`}
                  style={{
                    width: "100%",
                    height: 700,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    cursor:"pointer"
                  }}
                  onClick={()=> {}}
                />
              )}
            </CustomCarousel>
            </div>
          }
          {!themeConfig.hidePostFeatureProducts && !!products && !!products.length && (
            <>
              <div className="featured-products" style={{flexDirection: 'column', gap:40, flex:"0.5 1 0%", alignItems:"flex-end" }}>
                <Headline4 translationKey="_FEATURED_PRODUCTS_">Fremhævede produkter</Headline4>
              {
                _.shuffle(products).slice(0,productSlice).map(item => (
                  <ProductNormal key={item.ProductID}             
                    productID={item.ProductID}
                    showTag={item.enableDiscount ? true : false}
                    tagOptions={{
                        fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                        showClose: false,
                    }}
                    tagStyles={{borderRadius: 8}}
                    points={(item.enableMulti && item.minCount > 0) 
                      ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}` 
                      : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`} 
                    liked={false}
                    className="productlarge"
                    style={{ boxShadow: `0px 0px 100px rgba(34, 34, 34, 0.15)` }}
                    text2={item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
                    text1={item.manufacturer}
                    wishlistCallback={() => alert("you LIKED this Product!")}
                    imageSrc={`url(${item.imageSrc})`}
                    activityName='posts'
                  />
                ))
              }
              </div>
            </>
          )}
        </div>
        </div>
        {!themeConfig.hideHaveYouSeenBlock && !!products?.length && <div 
          style={{
            marginTop: 100, 
            marginBottom: -100, 
            paddingTop: 100, 
            paddingBottom: 100, 
            backgroundColor: styleGuide.color3,
          }} 
          className="horizontal-scroll-wrapper have-you-seen-products">
          <HorizontalScrollBlock caption={translate("_POST_DETAILS_HAVE_YOU_SEEN_PRODUCTS_", "Har du set")}>
            {
              products.map((item) => (
                <ProductNormal
                  productID={item.ProductID}
                  showTag={item.enableDiscount ? true : false}
                  tagOptions={{
                      fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                      showClose: false,
                  }}
                  tagStyles={{borderRadius: 8}}
                  points={(item.enableMulti && item.minCount > 0) 
                    ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}` 
                    : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`} 
                  liked={false}
                  className="productlarge item"
                  style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                  text2={item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
                  text1={item.manufacturer}
                  wishlistCallback={() => alert("you LIKED this Product!")}
                  imageSrc={`url(${item.imageSrc})`}
                  activityName='posts'
                />
              ))
            }
          </HorizontalScrollBlock>
        </div>}
        {
            !!(postType === "taxCampaign") && 
            !!postDetails?.additionalInput && 
            <AdditionalInputFieldsV2 additionalInput={JSON.parse(postDetails?.additionalInput)} />
          }
    </>
  );
};

export default PostDetails;

export const AdditionalInputFieldsV1 = (props) => {
  const {additionalInput} = props
  const {captionText=" ", data=[]} = additionalInput
  const {brandData, userData, styleGuide, setUserData} = useContext(AppContext)
  const brandPointRatio = brandData?.brandPointRatio || null
  // const userTaxPercentage = userData?.taxPercentage || null
  const [rows, setRows] = useState([])

  const defaultPrice = Number(0).toLocaleString("da-DK", { style: "currency", currency: "DKK" })
  const [enteredPercentage, setEnteredPercentage] = useState(userData?.taxPercentage || 0)
  const [enteredPrice, setEnteredPrice] = useState(0)
  const [calculatedExternalPrice, setCalculatedExternalPrice] = useState(defaultPrice)
  const [calculatedInternalPrice, setCalculatedInternalPrice] = useState(defaultPrice)

  const submitTaxPercentage = async() => {
    if(!enteredPercentage) return
    const taxPercentage = enteredPercentage
    const response = await callAPI("/user/saveTaxPercentage", "POST", { body: { taxPercentage: taxPercentage } })
    if (response) {
        setUserData({ ...userData, taxPercentage: taxPercentage })
    }    
  }

  useEffect(() => {
    if(!data?.length) return
    setRows(data?.map(a => {
      a.calculatedDKK = Number(a.valueType === 'pt' ? a.inputValue / brandPointRatio : a.inputValue)
      a.calculatedTaxValue = !enteredPercentage ? 'xx.xx' : Number(a.calculatedDKK * (1 - enteredPercentage/100))
      a.inputValue = Number(a.inputValue)
      return a
    }))

  },[enteredPercentage])

  useEffect(() => {
    if(!enteredPrice || !enteredPercentage) { 
      setCalculatedExternalPrice(defaultPrice)
      setCalculatedInternalPrice(defaultPrice)
      return
    } else {
      const percentage = 1 - (enteredPercentage/100)
      const extPrice = !enteredPrice || !enteredPercentage ? defaultPrice : Number(enteredPrice / percentage)?.toLocaleString("da-DK", { style: "currency", currency: "DKK" })
      const intPrice = !enteredPrice || !enteredPercentage ? defaultPrice : Number(enteredPrice * percentage)?.toLocaleString("da-DK", { style: "currency", currency: "DKK" })
      setCalculatedExternalPrice(extPrice)
      setCalculatedInternalPrice(intPrice)
      return   
    }
  },[enteredPercentage, enteredPrice])

  return !data ? null : <>
      <div 
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: 50
      }}
    >
      {/* blue box */}
      <div
        style={{
          display: "flex",
          background: "#235c76" || styleGuide.color16,
          padding: "35px 40px",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10%"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10
          }}
        >
          <ContentLarge fallbackText="Indtast din trækprocent" style={{color: "white", fontWeight: 400}} />
          <div style={{ display:"flex", gap: 5 }}>
            <Input
              style={{background: "white", border:"none", borderRadius: 8}}
              defaultValue={enteredPercentage}
              type="number"
              inputProps={{ style: {textAlign: "right"} }}
              onChange={(e) => setEnteredPercentage(Number(e?.target?.value || 0))}
              disableUnderline={true}
            />
            <Headline2 fallbackText="%" style={{color: "white"}} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10
          }}
        >
          <ContentLarge fallbackText="Indtast prisen på dit køb" style={{color: "white", fontWeight: 400}} />
          <div style={{ display:"flex", gap: 5 }}>
            <Input
              style={{background: "white", border:"none", borderRadius: 8}}
              defaultValue=""
              type="number"
              inputProps={{ style: {textAlign: "right"} }}
              onChange={(e) => setEnteredPrice(Number(e?.target?.value || 0))}
              disableUnderline={true}
            />
            <Headline2 fallbackText="kr" style={{color: "white"}} />
          </div>
        </div>
        <IconButtonMedium
          title="Gem procent til profil"
          style={{ marginLeft: 8, height: 50, width: 50, borderRadius: 20 }}
          onClick={submitTaxPercentage}
          icon={
            <Save
              fill='white'
              style={{ height: 30, width: 30 }}
              stroke="#bf150a"
              strokeWidth="1.5px"
            />
          }
        />
      </div>

      {/* red box */}
      <div style={{ background: "#bf150a", display: "flex", padding: "35px 40px", flexWrap: "wrap", gap: "2rem", alignItems: "center" }} >
        <ContentLarge fallbackText="Din pris efter skat gennem Tryg klubben" style={{color: "white"}} />
        <div className='arrowActivation'>
          <ArrowRight style={{ width: 30, height: 30 }} fill={"white"} />
        </div>
        <Headline2 fallbackText={`${calculatedInternalPrice}`} style={{color: "white"}} />
      </div>
      
      {/* red box text */}
      <div>
      <ExplainerText fallbackText={<span style={{color: styleGuide.color7}}>Samme Personal Shopper køb i Tryg Klubben vil kun koste dig ca. <b style={{color: styleGuide.color7}}>{calculatedInternalPrice} efter skat*</b> - næsten halv pris, da varen betales med ubeskattede midler.</span>} />
      <br/>
      <ExplainerTextTiny fallbackText={`Udregning: ${Number(enteredPrice).toLocaleString("da-DK", { style: "currency", currency: "DKK" })} * ((100 - ${Number(enteredPercentage)})/100) = ${Number(enteredPrice * ((100 - enteredPercentage)/100)).toLocaleString("da-DK", { style: "currency", currency: "DKK" })}`} />
      </div>

      {/* grey box */}
      <div style={{ background: "grey", display: "flex", padding: "25px 30px", flexWrap: "wrap", alignItems: "center"}} >
        <ContentNormal fallbackText="Beløb før skat udenfor Tryg Klubben:" style={{color: "white"}} />&nbsp;
        <ContentNormal fallbackText={`${calculatedExternalPrice}`} style={{color: "white"}} />
      </div>
      {/* grey box text */}
      <div>
      <ExplainerText fallbackText={<span style={{color: styleGuide.color7}}>Hvis dit Personal Shopper køb har en pris på <b style={{color: styleGuide.color7}}>{Number(enteredPrice).toLocaleString("da-DK", { style: "currency", currency: "DKK" })}</b> i en almindelig butik, skal du tjene ca. <b style={{color: styleGuide.color7}}>{calculatedExternalPrice} før skat*</b> for at have råd til den.</span>} />
      <br/>
      <ExplainerTextTiny fallbackText={`Udregning: ${Number(enteredPrice).toLocaleString("da-DK", { style: "currency", currency: "DKK" })} / (100 - ${Number(enteredPercentage)}) * 100 = ${Number(enteredPrice / (100 - (enteredPercentage || 0)) * 100).toLocaleString("da-DK", { style: "currency", currency: "DKK" })}`} />
      </div>
    </div>
    <br/>
    <ContentLarge fallbackText={captionText} />
    <table style={{maxWidth: "fit-content"}}>
      <tbody>
        {
          rows?.map((r,i) => <tr key={i}>
            {/* <td>&bull;</td> */}
            <td style={{textAlign: "end"}}>{r?.inputValue?.toLocaleString('da-DK')}&nbsp;</td>
            <td style={{textAlign: "end"}}>{r?.valueType}. &nbsp;</td>
            <td style={{textAlign: "end"}}>{r?.inputText} &nbsp;</td>
            <td style={{textAlign: "end"}}>{`${r?.calculatedTaxValue?.toLocaleString('da-DK', { style: "currency", currency: "DKK" })}`}</td>
            </tr>)
        }
      </tbody>
    </table>
  </>
}

export const AdditionalInputFieldsV2 = (props) => {
  const {additionalInput} = props
  const {captionText=" ", data=[], helpText1=""} = additionalInput
  const {brandData, userData, styleGuide, setUserData} = useContext(AppContext)
  const brandPointRatio = brandData?.brandPointRatio || null
  const [rows, setRows] = useState([])

  const defaultPrice = Number(0).toLocaleString("da-DK", { style: "currency", currency: "DKK" })
  const [enteredPercentage, setEnteredPercentage] = useState(userData?.taxPercentage || 0)
  const [enteredPrice, setEnteredPrice] = useState(0)
  const [calculatedInternalPrice, setCalculatedInternalPrice] = useState(defaultPrice)

  const submitTaxPercentage = async() => {
    if(!enteredPercentage) return
    const taxPercentage = enteredPercentage
    const response = await callAPI("/user/saveTaxPercentage", "POST", { body: { taxPercentage: taxPercentage } })
    if (response) {
        setUserData({ ...userData, taxPercentage: taxPercentage })
    }    
  }

  const helpTextBelowRedBox = helpText1 || `Ved køb via ${brandData?.brandName || ''} Klubbens personal shopper-service pålægges et administrationsgebyr. Gebyrets størrelse varierer, men kontakt venligst personal shopper-teamet for en prisvurdering i point for din rejse eller andre indkøb. Du kan herefter vælge at godkende prisen.`

  useEffect(() => {
    if(!data?.length) return
    setRows(data?.map(a => {
      a.calculatedDKK = Number(a.valueType === 'pt' ? a.inputValue / brandPointRatio : a.inputValue)
      a.calculatedTaxValue = !enteredPercentage ? 'xx.xx' : Number(a.calculatedDKK * (enteredPercentage/100))
      a.inputValue = Number(a.inputValue)
      return a
    }))
  },[enteredPercentage])

  useEffect(() => {
    if(!enteredPrice || !enteredPercentage) { 
      setCalculatedInternalPrice(defaultPrice)
      return
    } else {
      const percentage = enteredPercentage/100
      const intPrice = !enteredPrice || !enteredPercentage ? defaultPrice : Number(enteredPrice * percentage)?.toLocaleString("da-DK", { style: "currency", currency: "DKK" })
      setCalculatedInternalPrice(intPrice)
      return   
    }
  },[enteredPercentage, enteredPrice])

  return !data ? null : <>
      <div 
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: 50
      }}
    >
      {/* blue box */}
      <div
        style={{
          display: "flex",
          background: "#235c76" || styleGuide.color16,
          padding: "35px 40px",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10%"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10
          }}
        >
          <ContentLarge fallbackText="Indtast din trækprocent" style={{color: "white", fontWeight: 400}} />
          <div style={{ display:"flex", gap: 5 }}>
            <Input
              style={{background: "white", border:"none", borderRadius: 8}}
              defaultValue={enteredPercentage}
              type="number"
              inputProps={{ style: {textAlign: "right"} }}
              onChange={(e) => setEnteredPercentage(Number(e?.target?.value || 0))}
              disableUnderline={true}
            />
            <Headline2 fallbackText="%" style={{color: "white"}} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10
          }}
        >
          <ContentLarge fallbackText="Indtast prisen på dit køb" style={{color: "white", fontWeight: 400}} />
          <div style={{ display:"flex", gap: 5 }}>
            <Input
              style={{background: "white", border:"none", borderRadius: 8}}
              defaultValue=""
              type="number"
              inputProps={{ style: {textAlign: "right"} }}
              onChange={(e) => setEnteredPrice(Number(e?.target?.value || 0))}
              disableUnderline={true}
            />
            <Headline2 fallbackText="kr" style={{color: "white"}} />
          </div>
        </div>
        <IconButtonMedium
          title="Gem procent til profil"
          style={{ marginLeft: 8, height: 50, width: 50, borderRadius: 20 }}
          onClick={submitTaxPercentage}
          icon={
            <Save
              fill='white'
              style={{ height: 30, width: 30 }}
              stroke="#bf150a"
              strokeWidth="1.5px"
            />
          }
        />
      </div>

      {/* red box */}
      <div style={{ background: "#bf150a", display: "flex", padding: "35px 40px", flexWrap: "wrap", gap: "2rem", alignItems: "center" }} >
        <ContentLarge fallbackText="Du betaler dette beløb i skat, og så er rejsen din" style={{color: "white"}} />
        <div className='arrowActivation'>
          <ArrowRight style={{ width: 30, height: 30 }} fill={"white"} />
        </div>
        <Headline2 fallbackText={`${calculatedInternalPrice}`} style={{color: "white"}} />
      </div>
      
      {/* red box text */}
      <div>
      <ContentNormal fallbackText={helpTextBelowRedBox} />
      <br/>
      {/* Udregning: 2.000 * 0,37 = 740 kr. */}
      <ExplainerText fallbackText={`Udregning: ${Number(enteredPrice).toLocaleString("da-DK", { style: "currency", currency: "DKK" })} * (${Number(enteredPercentage)}/100) = ${Number(enteredPrice * (enteredPercentage/100)).toLocaleString("da-DK", { style: "currency", currency: "DKK" })}`} />
      </div>
    </div>
    <br/>
    <ContentLarge fallbackText={captionText} />
    <table style={{maxWidth: "fit-content"}}>
      <tbody>
        {
          rows?.map((r,i) => <tr key={i}>
            {/* <td>&bull;</td> */}
            <td style={{textAlign: "end"}}>{r?.inputValue?.toLocaleString('da-DK')}&nbsp;</td>
            <td style={{textAlign: "end"}}>{r?.valueType}. &nbsp;</td>
            <td style={{textAlign: "end"}}>{r?.inputText} &nbsp;</td>
            <td style={{textAlign: "end"}}>{`${r?.calculatedTaxValue?.toLocaleString('da-DK', { style: "currency", currency: "DKK" })}`}</td>
            </tr>)
        }
      </tbody>
    </table>
  </>
}