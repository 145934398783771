import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "contexts/AppContext";
import ExplainerText from "components/elements/ExplainerText";
import { useNavigate } from "react-router-dom";
import Image from "components/elements/Image";
import "./CountDownBlock.css";
import { translate } from "utils/Translate";
import moment from "moment";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonLarge3 from "components/elements/ButtonLarge3";
import { callAPI } from "utils/API";

function hexToRgb(hex, a) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${a})`
    : null;
}

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown > 0 && countDown);
};

const CountDownBlock = (props) => {
  const navigate = useNavigate();
  const [countDownDetails, setCountDownDetails] = useState({});
  const { competition, buttonTitle, countdown } = props;
  const { styleGuide, langCode } = useContext(AppContext);

  const isCountdown = competition
    ? true
    : !!countDownDetails && countDownDetails.isCountdown === 1
    ? true
    : false;

  const title = competition
    ? competition.moduleName || (competition && competition.title)
      ? competition.title
      : ""
    : !!countDownDetails &&
      !!countDownDetails.translations &&
      !!countDownDetails.translations.length
    ? !!countDownDetails.translations.find(
        (translation) => translation.languageKey === langCode
      ) ? countDownDetails.translations.find(
        (translation) => translation.languageKey === langCode
      )?.title
    : countDownDetails?.title
    : "";
  const description = competition
    ? competition.moduleDescription ||
      (competition && competition.shortDescription)
      ? competition.shortDescription
      : ""
    : !!countDownDetails &&
      !!countDownDetails.translations &&
      !!countDownDetails.translations.length
    ? countDownDetails.translations.find(
        (translation) => translation.languageKey === langCode
      ) ? countDownDetails.translations.find(
        (translation) => translation.languageKey === langCode
      ).description
    : countDownDetails?.description
    : "";
  const countDownColor = !!countDownDetails && countDownDetails.textColor;
  const imageHeight =
    (!!countDownDetails && countDownDetails.imageHeight) || 600;
  const countDownButtonTitle =
    buttonTitle || (!!countDownDetails && countDownDetails.buttonTitle);
  const countDownTimeStart = competition
    ? competition.timeStart || (competition && competition.startDate)
      ? competition.startDate
      : ""
    : !!countDownDetails && countDownDetails.endDate;
  const countDownTimeEnd =
    (competition && competition.timeStart) ||
    (competition && competition.endDate);
  const countDownTimeStatus = competition && competition.status;
  const countDownBannerImageURL = competition
    ? competition?.imgUrl?.length
      ? competition.imgUrl[0].imageOriginSource
      : "https://cdn.upgrader.club/f9e58318-dfe9-470d-8286-841881819c04-default.jpeg"
    : (!!countDownDetails && countDownDetails.imageUrl) ||
      "https://cdn.upgrader.club/f9e58318-dfe9-470d-8286-841881819c04-default.jpeg";
  const countDownShopCategoryLink =
    !!countDownDetails &&
    countDownDetails.ShopID &&
    `shop/${countDownDetails.ShopID}?category=${countDownDetails.category
      ?.split(" ")
      .join("_")}`;
  const countDownCompetitionLink =
    (competition && `/competition/${competition.CompetitionID}`) ||
    (!!countDownDetails &&
      countDownDetails.CompetitionID &&
      `/competition/${countDownDetails.CompetitionID}`);
  const countDownCalendarLink =
    competition && `/christmas-calendar/${competition.CompetitionID}`;
  const countDownNewsLink =
    !!countDownDetails &&
    countDownDetails.PostID &&
    `/post/${countDownDetails.PostID}`;
  const [days, hours, minutes, seconds] = useCountdown(
    competition ? countDownTimeEnd : countDownTimeStart
  );
  const colorhex1 = hexToRgb(styleGuide.color16, 0);
  const colorhex2 = hexToRgb(styleGuide.color16, 0.5);
  const LastDate =
    competition?.competitionType === "CHRISTMAS_CALENDAR"
      ? competition.endDate
      : countDownDetails.endDate;
  const CurrentDate = new Date().toISOString().slice(0, 10);
  const isExpired = CurrentDate > LastDate;

  const fetchCountdown = async () => {
    const countdown = await callAPI(`/countdown`);
    setCountDownDetails(countdown || {});
  };

  useEffect(() => {
    fetchCountdown();
  }, []);

  return (
    <>
      {isCountdown && !isExpired && (
        <div style={{ ...props.styles, position: "relative" }}>
          <Image
            className="countDownModal"
            src={`url(${countDownBannerImageURL})`}
            style={{
              width: "100%",
              objectFit: "cover",
            }}
          >
            {countdown === false ? null : (
              <div
                className="countDownTimer"
                style={{
                  backgroundColor: styleGuide.color17,
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: styleGuide.color1,
                      fontFamily: styleGuide.fontFamily,
                      fontSize: styleGuide.headline2FontSize
                    }}
                    className="countDownTimeTitle"
                  >
                    {days}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContent"
                  >
                    {translate("_COUNTDOWN_DAYS_SHORT_", "D")}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContentBig"
                  >
                    {translate("_COUNTDOWN_DAYS_", "Dage")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: styleGuide.color1,
                      fontFamily: styleGuide.fontFamily,
                      fontSize: styleGuide.headline2FontSize
                    }}
                    className="countDownTimeTitle"
                  >
                    {hours}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContent"
                  >
                    {translate("_COUNTDOWN_HOURS_SHORT_", "T")}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContentBig"
                  >
                    {translate("_COUNTDOWN_HOURS_", "Timer")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: styleGuide.color1,
                      fontFamily: styleGuide.fontFamily,
                      fontSize: styleGuide.headline2FontSize
                    }}
                    className="countDownTimeTitle"
                  >
                    {minutes}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContent"
                  >
                    {translate("_COUNTDOWN_MINUTES_SHORT_", "M")}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContentBig"
                  >
                    {translate("_COUNTDOWN_MINUTES_", "Minutter")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: styleGuide.color1,
                      fontFamily: styleGuide.fontFamily,
                      fontSize: styleGuide.headline2FontSize
                    }}
                    className="countDownTimeTitle"
                  >
                    {seconds}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContent"
                  >
                    {translate("_COUNTDOWN_SECONDS_SHORT_", "S")}
                  </div>
                  <div
                    style={{ color: styleGuide.color1, fontSize: styleGuide.headline6FontSize, fontFamily: styleGuide.secondaryFontFamily, }}
                    className="countDownTimerContentBig"
                  >
                    {translate("_COUNTDOWN_SECONDS_", "Sekunder")}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`countDownBox ${
                countdown === false && "countDownBoxFalse"
              }`}
              // style={{ background: `linear-gradient(248.66deg, ${colorhex1} 0%, ${colorhex2} 100%)` }}
            >
              <div
                style={{
                  color: countDownColor
                    ? styleGuide.color18
                    : styleGuide.color1,
                  fontSize: styleGuide.headline1FontSize,
                  fontFamily: styleGuide.fontFamily
                }}
                className="countDownTitle"
              >
                {title}
              </div>
              <div
                style={{
                  color: countDownColor
                    ? styleGuide.color18
                    : styleGuide.color1,
                  fontSize: styleGuide.headline5FontSize,
                  fontFamily: styleGuide.fontFamily
                }}
                className={`countDownContent ${
                  countdown === false && "countDownContentFalse"
                }`}
              >
                {description}
              </div>
              <div className="countDownButtonM">
                <ButtonMedium1
                  onMedium1
                  showIcon={false}
                  style={{ maxWidth: "max-content" }}
                  fallbackText={
                    countDownButtonTitle
                      ? translate(
                          "_COUNTDOWN_BUTTON_TITLE_",
                          countDownButtonTitle
                        )
                      : "Se nyheden"
                  }
                  onClick={() => {
                    navigate(
                      `${
                        countDownButtonTitle === "Se udvalget"
                          ? countDownShopCategoryLink
                          : countDownButtonTitle === "Se konkurrencen" ||
                            countDownButtonTitle === "Læs mere" ||
                            countDownButtonTitle === "Deltag i konkurrencen"
                          ? countDownCompetitionLink
                          : countDownButtonTitle === "Deltag"
                          ? countDownCalendarLink
                          : countDownNewsLink
                      }`
                    );
                  }}
                ></ButtonMedium1>
              </div>
              <div className="countDownButtonL">
                <ButtonLarge1
                  style={{ maxWidth: "max-content" }}
                  fallbackText={
                    countDownButtonTitle
                      ? translate(
                          "_COUNTDOWN_BUTTON_TITLE_",
                          countDownButtonTitle
                        )
                      : "Se nyheden"
                  }
                  onClick={() => {
                    navigate(
                      `${
                        countDownButtonTitle === "Se udvalget"
                          ? countDownShopCategoryLink
                          : countDownButtonTitle === "Se konkurrencen" ||
                            countDownButtonTitle === "Læs mere" ||
                            countDownButtonTitle === "Deltag i konkurrencen"
                          ? countDownCompetitionLink
                          : countDownButtonTitle === "Deltag"
                          ? countDownCalendarLink
                          : countDownNewsLink
                      }`
                    );
                  }}
                />
              </div>
            </div>
          </Image>
          {props && props.cancelButton && (
            <div style={{ position: "absolute", bottom: 20, right: 20 }}>
              <ButtonLarge3
                showIcon={false}
                style={{ maxWidth: "max-content" }}
                fallbackText={
                  props.cancelLoading
                    ? translate("_COUNTDOWN_BLOCK_BUTTON_CANCEL_", "Afmeld")
                    : translate("_COUNTDOWN_BLOCK_BUTTON_CANCELLED_", "Afmeldt")
                }
                onClick={props.cancelButtonFunc}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CountDownBlock;
