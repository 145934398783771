import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { AppContext } from "contexts/AppContext";
import { callAPI } from "utils/API"
import { removeProductFromBasket, updateProductQuantityInBasket } from "utils/basket"
import { setupBasketData } from "utils/setup"
import Header1 from "components/blocks/header/Header1";
import Header2 from "components/blocks/header/Header2";
import Header3 from "components/blocks/header/Header3";
import CaptionText from "components/elements/CaptionText"
import ExplainerText from "components/elements/ExplainerText"
import Headline6 from "components/elements/Headline6"
import Image from "components/elements/Image"
import Dash from "components/icons/Dash"
import Plus from "components/icons/Plus"
import Close from "components/icons/Close";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import { translate } from "utils/Translate";
import Drawer from "components/blocks/drawer/Drawer";
import moment from "moment";
import { getCookie, pointsConvertor } from "utils/general";
import Activity from "utils/Activity";
import Header4 from "./Header4";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CaretDown from "components/icons/CaretDown";
import CaretDown2 from "components/icons/CaretDown2";


function Header(props) {
  const { defaultTheme, userData, brandData } = useContext(AppContext);
  const { style, className } = props
  const [pointsUpdate, setPointsUpdate] = useState({
    showAlert: false,
    pointsFromLastLogin: 0,
  })

  const HeaderCommonStyle = {
    zIndex: 2,
    position: 'absolute',
    top: 0
  }

  const selectHeader = {
    "ThemeONE": <Header1 pointsUpdate={pointsUpdate} hidePointsUpdate={() => setPointsUpdate({...pointsUpdate, showAlert: false})} style={{ ...HeaderCommonStyle, ...style }} {...className} />,
    "ThemeTWO": <Header2 style={{ ...HeaderCommonStyle, ...style }} {...className} />,
    "ThemeTHREE": <Header3 style={{ ...HeaderCommonStyle, ...style }} {...className} />,
    "ThemeFOUR": <Header4 style={{ ...HeaderCommonStyle, ...style }} {...className} />
  }

  useEffect(async () => {
    const cookieName = 'pointsNotificationDisplayed'

    if(document.cookie && document.cookie.includes(cookieName)) { 
      const expiry = new Date(getCookie(cookieName))
      const currentTime = new Date()
      if (expiry >= currentTime) {
        return
      }
    }
  
    const points = await callAPI('/user/pointsSinceLastLogin')
    if(!points || !points.pointsFromLastLogin || isNaN(points.pointsFromLastLogin)) return

    const cookieExpiry = moment().endOf('day')
    document.cookie = `${cookieName}=${cookieExpiry};expires=${cookieExpiry}`
    setPointsUpdate({ showAlert: true, pointsFromLastLogin: points.pointsFromLastLogin })
  },[userData])

  useEffect(() => {
    if(!!pointsUpdate) setTimeout(() => setPointsUpdate(false), 5000)
  },[pointsUpdate])

  return <>
    <BasketQuickView />
    {selectHeader[defaultTheme]}
  </>
}

export default Header;


export const BasketQuickView = (props) => {
  const navigate = useNavigate()
  const { styleGuide, showBasketQuickView, setshowBasketQuickView, basket, userData,brandData } = useContext(AppContext);
  const [totalPrice, settotalPrice] = useState(0);

  const flexRow = { display: 'flex', flex: 1 }
  const flexCol = { display: 'flex', flex: 1, flexDirection: 'column' }

  const calculateTotalPrice = () => {
    let cost = 0
    basket.forEach(item => {
      if(!item.product || !item.product.length) return
      const productDetails = item.product[0]
      if(!productDetails) return
      const price = (productDetails.discountPointComputed ? productDetails.discountPointComputed : productDetails.productPointComputed) * item.amount
      cost += price
    });
    settotalPrice(cost)
  //   TagManager.dataLayer({
  //     dataLayer: {
  //       event: 'view_cart',
  //       ecommerce: {
  //         currency: 'DKK',
  //         value: cost,
  //         items: basket.map((item, index) => {
  //             const productDetails = item.product && item.product.length ? item.product[0] : {}
  //             const allVariations = productDetails.variations || []
  //             const selectedVariation = allVariations.filter(v => v.VariationID === item.VariationID)[0]

  //             const price = (productDetails.discountPointComputed ? productDetails.discountPointComputed : productDetails.productPointComputed) * item.amount
  //             return {
  //                 item_id: productDetails.ProductID,
  //                 item_name: productDetails.elementTitle,
  //                 discount: productDetails.discountPointComputed ? productDetails.productPointComputed - productDetails.discountPointComputed : 0,
  //                 index: index,
  //                 item_brand: productDetails.manufacturer,
  //                 item_category: productDetails.categories?.length ? productDetails.categories.map(c => c.categoryName).join() : '',
  //                 item_variant: selectedVariation?.variationTitle,
  //                 price: price,
  //                 quantity: item.amount
  //             }
  //         })
  //       }
  //     },
  // });
  }

  useEffect(() => {
    if(!basket || !basket.length) return
    calculateTotalPrice()
  }, [basket, userData]);


  return <>
    <Drawer visible={showBasketQuickView} closeHandler={() => setshowBasketQuickView(false)}>
        <div style={{ ...flexCol, position: 'relative', backgroundColor: styleGuide.color1 }}>
          <div style={{ ...flexCol, padding: `30px 40px 110px 40px`, maxHeight: '100vh', overflowY: 'scroll' }}>
            <div style={{ ...flexRow, justifyContent: 'space-between', alignItems: 'center' }}>
              {
                !!basket && !!basket.length
                  ? <Headline6>
                      { translate("_BASKET_QUANTITY_CAPTION_",`Du har ${basket.length} ting i kurven`).replace('##', basket.length) }
                    </Headline6> 
                  : <Headline6 translationKey="_BASKET_EMPTY_CAPTION_" fallbackText={`Din kurv er tom`} />
              }
              <Close fill={styleGuide.color7} style={{ width: 13, height: 13, cursor: 'pointer' }} onClick={() => setshowBasketQuickView(false)} />
            </div>
            {
              !!basket && basket.map((item, idx) => <BasketItemCard baskets={basket} basket={item} key={idx} showDivider={idx < basket.length - 1} />)
            }
            <div style={{height: 200}} />
          </div>
          <div style={{ ...flexCol, position: 'absolute', bottom: 0, left: 0, right: 0 }}>
            {
             brandData?.features?.points === false || !!brandData?.features?.hideUserPointBalance ? null : !!basket && !!basket.length && <div style={{ ...flexRow, flexWrap: 'wrap', backgroundColor: styleGuide.color3, alignItems: 'center', justifyContent: 'center', padding: 5 }}>
                <ExplainerText fallbackText={
                  userData.walletAmountPoints - totalPrice >= 0 
                    ? translate('_WALLET_BALANCE_TEXT_' , `Du har nu ${(userData.walletAmountPoints - totalPrice) > 999 ? ((userData.walletAmountPoints - totalPrice)/1000).toFixed(3):(userData.walletAmountPoints - totalPrice)} point tilbage`).replace('##', (userData.walletAmountPoints - totalPrice) > 999 ? ((userData.walletAmountPoints - totalPrice)/1000).toFixed(3):(userData.walletAmountPoints - totalPrice))
                    : translate('_BASKET_PRICE_EXCEEDS_BALANCE_TEXT_' , 'Du har ikke point nok til at gennemføre ordren')
                  } />
              </div>
            }
            <div className='basket_buttons' style={{ display: 'flex', backgroundColor: styleGuide.color2, padding: '22px  40px', justifyContent: 'space-between' }}>
              <ButtonMedium4 style={{ padding: '0 10px' }} showIcon={false} translationKey="_CONTINUE_SHOPPING_" fallbackText="Shop videre" onClick={() => setshowBasketQuickView(false)} />
            <ButtonMedium1 style={{ padding: '0 10px' }} showIcon={false} translationKey="_GO_TO_BASKET_" fallbackText="Gå til kurven" 
                onClick={() => { 
                  Activity({activityType: 'click',activityName: 'header',activityArgument: 'Basket',activityArgumentID: ''})
                  navigate('/basket'); setshowBasketQuickView(false) }} />
            </div>
          </div>
        </div>
    </Drawer>
  </>
}

export const BasketItemCard = (props) => {
  const navigate = useNavigate()
  const { styleGuide, userData, setBasket, accessibleShops, brandData } = useContext(AppContext)
  const hideQuantityModifier = brandData?.features?.enableOnePointUsers || false
  const { basket, showDivider, baskets } = props
  const productDetails = basket.product && basket.product.length ? basket.product[0] : {}
  productDetails.ShopID = !!productDetails.ShopID ? productDetails.ShopID : accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null
  const allVariations = productDetails.variations || []
  const selectedVariation = allVariations.filter(v => v.VariationID === basket.VariationID)[0]
  const price = (productDetails.discountPointComputed ? productDetails.discountPointComputed : productDetails.productPointComputed) * basket.amount

  const variationImage = () => {
    let returnImg = ''

    const allImages = productDetails.images || []
    if (!allImages.length) return returnImg

    const variationImages = selectedVariation ? selectedVariation.images : []
    returnImg = variationImages.length ? variationImages[0].imageOriginSource : ''
    if (returnImg) return returnImg

    const featuredImage = allImages.filter(item => !!item.featuredImage)
    returnImg = featuredImage.length ? featuredImage[0].imageOriginSource : ''
    if (returnImg) return returnImg

    return allImages.sort((a, b) => a.poolOrder - b.poolOrder)[allImages.length - 1].imageOriginSource
  }

  const imageUrl = variationImage()

  const productReaction = async (product) => {
    const ReactionID = product.reaction ? product.reaction.ReactionID : null
    await callAPI("/product/reaction", "POST", { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...ReactionID && { ReactionID } } });
  }

  const moveToWishlist = async () => {
    productReaction(productDetails)
    await deleteItemFromBasket()
  }

  const deleteItemFromBasket = async () => {
    await removeProductFromBasket(basket.BasketID)
    await setupBasketData({ userData, setBasket })
  }

  const deleteItemsFromBasket = async () => {
    !!baskets && !!baskets.length && baskets.forEach( async(item) => {
      if(basket.ProductPackageID === item.ProductPackageID) {
        await removeProductFromBasket(item.BasketID)
        await setupBasketData({ userData, setBasket })
      }
    })
  }

  const increaseQuantity = async () => {
    await updateProductQuantityInBasket(basket.BasketID, basket.amount + 1)
    await setupBasketData({ userData, setBasket })
  }

  const decreaseQuantity = async () => {
    if (basket.amount === 1) return
    await updateProductQuantityInBasket(basket.BasketID, basket.amount - 1)
    await setupBasketData({ userData, setBasket })
  }

  const showQuantity = (basket) => {
    let productShopID = basket.ShopID;
    let shopSelectionLimit = accessibleShops && accessibleShops.length ? accessibleShops.filter(basketItem => basketItem.ShopID === productShopID) : [] ;
    shopSelectionLimit = shopSelectionLimit.length > 0 ? shopSelectionLimit[0].shopSelectionLimit : 0;    
    if (shopSelectionLimit === 0) {
         return true
        }
        else {
          return false
        }
  }

  const Divider = () => <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch', height: 1, backgroundColor: styleGuide.color4 }} />
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  return <>
    <div id='basket-item-card' style={{ backgroundColor: styleGuide.color1, overflow: 'hidden', minHeight: 165 }}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '1rem', padding: '30px 0px' }} >
          {!!imageUrl && <div
            style={{ display: 'flex', cursor: 'pointer', border: `0.5px solid ${styleGuide.color2}` }}
            onClick={() => navigate(`/product/${productDetails.ProductID}`)}>
            <Image style={{ height: 100, width: 80 }} src={`url(${imageUrl})`} />
          </div>
          }
          <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                {productDetails.manufacturer && <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/product/${productDetails.ProductID}`)}><Headline6 fallbackText={productDetails.manufacturer} /></div>}
                {productDetails.elementTitle && <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/product/${productDetails.ProductID}`)}><ExplainerText style={{ color: styleGuide.color5 }}>{productDetails.elementTitle + (!!productDetails.enableMulti ? ', ' + productDetails.minCount + ' stk' : '')}</ExplainerText></div>}
                <div style={{ display: 'flex', marginTop: '0.25rem', alignItems: 'center' }}>
                  {
                    !!selectedVariation && ((selectedVariation.colorHexcode && selectedVariation.variationSize) || selectedVariation.variationTitle) && <>
                      <ExplainerText
                        style={{ color: styleGuide.color5 }}
                        fallbackText={selectedVariation.variationSize ? `${selectedVariation.variationSize}` : selectedVariation.variationTitle}
                      />
                      { 
                        selectedVariation.colorName 
                            ? <ExplainerText style={{ color: styleGuide.color5 }} fallbackText={`, ${selectedVariation.colorName}`} />
                            : null 
                      }
                    </>
                  }
                </div>
                <div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center', marginTop: '0.25rem' }}>
                  {!hideQuantityModifier && <div style={(!!basket && !basket.ProductPackageID) ? productDetails.enableMulti !== 1 ? { cursor: 'pointer' } : {cursor:'not-allowed'} : {cursor:'not-allowed'}} onClick={((!!basket && !basket.ProductPackageID)) && productDetails.enableMulti !== 1 ? decreaseQuantity : null}><Dash /></div>}
                  <Headline6 style={{ lineHeight: '21px' }} fallbackText={basket.amount} />
                  {!hideQuantityModifier && <div style={(!!basket && !basket.ProductPackageID) ? showQuantity(basket) && productDetails.enableMulti !== 1 ? {cursor: 'pointer' } : {cursor:'not-allowed'} : {cursor:'not-allowed'}} title={showQuantity(basket) ? null : 'Du kan ikke vælge flere'} onClick={ (!!basket && !basket.ProductPackageID) && showQuantity(basket) && productDetails.enableMulti !== 1 ? increaseQuantity : null }><Plus /></div>}
                </div>
                {
                  (!productDetails.reaction || !productDetails.reaction.ReactionID) &&
                  <div onClick={moveToWishlist} style={{ cursor: 'pointer' }}>
                    <ExplainerText translationKey="_BASKET_MOVE_TO_WISHLIST_LABEL_" fallbackText="Flyt til ønskelisten" />
                  </div>
                }
              </div>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
              <CaptionText style={{ color: styleGuide.color16 }} fallbackText={`${pointsConvertor(brandData,price ,pointSuffix)}`} />
              <div onClick={(!!basket && !!basket.ProductPackageID) ? deleteItemsFromBasket : deleteItemFromBasket} style={{ cursor: 'pointer' }}>
                  <ExplainerText translationKey="_BASKET_REMOVE_ITEM_LABEL_" fallbackText="Fjern" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !!showDivider && <Divider />
      }
    </div>
  </>
}

export const ShopMenu = ({ caption = "Shop", style = {} }) => {
  const {accessibleShops} = useContext(AppContext);
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (!accessibleShops?.length) ? null :
    (accessibleShops.length === 1)
      ? <Link style={{textDecoration: 'none' , ...style}} to={`shop/${accessibleShops[0].ShopID}`}>{accessibleShops[0].moduleName}</Link>
      : <div style={{position: "relative"}}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<CaretDown2 fill="white"/>}
        >
          <span style={{textTransform: 'none',...style}}>{caption}</span>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {
            accessibleShops.map(s => <MenuItem onClick={() => navigate(`shop/${s.ShopID}`)} >{s.moduleName}</MenuItem>)
          }
        </Menu>
      </div>

}